import { Box } from "@material-ui/core";
import styled from "styled-components";

const Colored = styled.span`
  color: #fb52b7;
`;

const LogoWrapper = styled.div`
  font-size: 2rem;
  text-align: center;
`;

const Logo = () => {
  return (
    <Box component="div" m={3}>
      <LogoWrapper>
        <div>
          SUICI<Colored>D</Colored>E
        </div>
        <div>
          S<Colored>Q</Colored>UI
          <Colored>D</Colored>
        </div>
      </LogoWrapper>
    </Box>
  );
};

export default Logo;
